<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="版本号" prop="version">
        <a-input v-model.trim="formDatas.version" :disabled="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="版本文件" prop="filename">
        <a-upload :disabled="showType=='detail'" name="fileList" :headers="uploadHeaders" :data="{folder: '10'}" action="/uploadversion" :file-list="filenameList" @change="filenameUploaded">
          <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="versiondesc">
        <a-input v-model.trim="formDatas.versiondesc" :disabled="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="监控终端组" prop="monitorgroupid">
        <a-select v-model="formDatas.monitorgroupid" style="width: 180px;">
          <a-select-option v-for="(item, index) in monitorgroupLists" :key="index" :value="item.monitorgroupid">{{item.monitorgroupname}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否升级" prop="isupdate">
        <a-select v-model="formDatas.isupdate" style="width: 180px;">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option value="0">升级</a-select-option>
          <a-select-option value="1">不升级</a-select-option>
<!--          <a-select-option value="2">手动升级</a-select-option>-->
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import {addMonitorversion, getMonitorgroupListByCondition, modifyMonitorversion} from 'A/monitoring'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      monitorgroupLists:[],
      formDatas: {
        monitorversionid: '',
        version: '',
        filename: '',
        versiondesc: '',
        monitorgroupid:'',
        isupdate:'',
        name:'',
      },
      filenameList: [],
      formRules: {
        version: [{required: true, message: '请输入版本号'}],
        filename: [{required: true, message: '请上传版本'}],
        versiondesc: [{required: true, message: '请输入版本描述'}],
        monitorgroupid: [{required: true, message: '请选择终端组'}],
      },
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
    ...mapGetters(['uploadHeaders']),
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.getMonitorGroupData();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            if(key=="monitorgroupid"){
              this.formDatas.monitorgroupid=this.detailData.monitorgroupid
            }else{
              this.formDatas[key] = ''+this.detailData[key]||'';
            }
          }
          if(this.formDatas.filename) {
            let nameIndex = this.formDatas.filename.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = this.formDatas.filename.lastIndexOf('\\');
            }
            let fileName = this.formDatas.filename.substr(nameIndex+1);
            this.filenameList = [{
              uid: 'filenameuid',
              name: fileName,
              status: 'done',
              url: this.formDatas.filename,
            }];
          }
        })
      }
    },
    getMonitorGroupData() {
      getMonitorgroupListByCondition({}).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.monitorgroupLists = res.item;
        }
      }).catch(err => {
      })
    },
    resetDatas() {
      this.formDatas.monitorversionid = '';
      this.$refs.modalForm.resetFields();
      this.filenameList = [];
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            if(this.showType == 'add') {
              addMonitorversion(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyMonitorversion(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    filenameUploaded(info) {
      let fileList = [...info.fileList];

      fileList = fileList.slice(-1);

      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formDatas.filename = file.response.urlPath;
          this.formDatas.name=file.name;
        }
        return file;
      });
      if(fileList.length == 0) {
        this.formDatas.filename = '';
      }
      this.filenameList = fileList;
    },
  }
}
</script>