<template>
  <page-container title="软件中心" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="版本号" prop="version">
              <a-input v-model.trim="queryParams.version" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">版本信息列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorversionid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="isupdate" slot-scope="value">
            {{value==0?"升级":value==1?"不升级":"手动升级远控模块"}}
          </span>
          <span slot="uploadtime" slot-scope="text">
            <span :title="text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">{{text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </span>
<!--          <span slot="operation" slot-scope="value, record, index">-->
<!--            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>-->
<!--            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>-->
<!--            <a-button type="link" size="small" v-if="showDelete" @click="deleteConfirm(value, record, index)">删除</a-button>-->
<!--            <a-button type="link" size="small" v-if="showDelete" @click="deleteConfirm(value, record, index)">手动升级</a-button>-->
<!--          </span>-->
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.monitorversionid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.monitorversionid">修改</a-menu-item>
                <a-menu-item v-if="record.isupdate==2" :key="'update-'+record.monitorversionid">手动升级远控模块</a-menu-item>
                <a-menu-item v-if="deleteenable==1" :key="'delete-'+record.monitorversionid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {
  getMonitorversionListByCondition,
  deleteMonitorversion,
  stopClients,
  stopRemote,
  updateRemoteFile
} from 'A/monitoring'
import addOrEditModal from './addOrEditModal'
import {getCache, getItemFromArrayByKey} from "U/index";
export default {
  name:'remoteupdate',
  mixins: [pagination],
  components: {
    addOrEditModal
  },
  data() {
    return {
      moment,
      userid:'',
      showDelete:false,
      showAdvanced: false,
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控软终端',
          path: ''
        },
        {
          name: '远程升级',
          path: ''
        },
      ],
      queryParams: {
        version: '',
      },
      tableColumns: [
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '版本文件',
          dataIndex: 'filename',
          key: 'filename',
          ellipsis: true,
        },
        {
          title: '版本描述',
          dataIndex: 'versiondesc',
          key: 'versiondesc',
          ellipsis: true,
        },
        {
          title: '更新时间',
          dataIndex: 'uploadtime',
          key: 'uploadtime',
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: 'uploadtime' }
        },
        {
          title: '关联终端组',
          dataIndex: 'monitorgroupname',
          key: 'monitorgroupname',
          ellipsis: true,
          width: 150,
        },
        {
          title: '是否升级',
          dataIndex: 'isupdate',
          key: 'isupdate',
          ellipsis: true,
          width: 125,
          scopedSlots: { customRender: 'isupdate' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      deleteenable:'',
    }
  },
  computed: {
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.userid = logininfo.usersid;
        this.deleteenable=logininfo.deleteenable
        if(this.userid=='1'){
          this.showDelete=true
        }
      }
      this.getTableData()
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorversionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total= res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.monitorversionid);
      }).catch(()=>{
      });
    },
    delete(monitorversionid) {
      this.showLoading();
      if(monitorversionid) {
        let params = {
          monitorversionid
        };
        deleteMonitorversion(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    updateRemote(monitorversionid,monitorgroupid){
      updateRemoteFile({monitorversionid:monitorversionid,monitorgroupid:monitorgroupid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let monitorversionid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'monitorversionid', monitorversionid);
      if(type == 'delete') {
        this.deleteConfirm(monitorversionid, record)
      }else if(type == 'detail'){
        this.showModal(type,record)
      }else if(type == 'edit'){
        this.showModal(type,record)
      }else if(type == 'update'){
        this.updateRemote(monitorversionid,record.monitorgroupid)
      }
    },
  }
}
</script>